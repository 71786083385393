import { defineStore } from 'pinia'
import { ExamApi, AtenaApi } from '@/api-client'
import axiosInstance from '@/api/axios'
import { ref } from 'vue'
import { useAuth } from '@/auth/useAuth'

export const useAppStore = defineStore(
  'app',
  () => {
    const auth = useAuth()
    const lang = ref('pt')
    const onlineUsers = ref(null)
    const userRole = ref('')
    const statusList = ref([])

    const subdomain = computed(() => {
      return window.location.hostname.split('.')[0]
    })
    const isPiaui = computed(() => {
      return window.location.hostname.includes('piaui')
    })

    async function getStatus() {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsStatusGet()

        statusList.value = response.data
      } catch (err) {
        console.error('Error fetching status:', err.message)
      }
    }

    async function getOnlineUsers() {
      if (!auth.user.roles.includes('admin')) {
        return
      }
      try {
        const atenaApi = new AtenaApi(undefined, undefined, axiosInstance)
        const response = await atenaApi.apiCoreAdministratorUsersOnlinePost()

        onlineUsers.value = response.data.data
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    return {
      isPiaui,
      subdomain,
      lang,
      onlineUsers,
      userRole,
      getOnlineUsers,
      getStatus,
      statusList,
    }
  },
  {
    persist: [
      {
        paths: ['lang'],
        storage: localStorage,
      },
      {
        paths: ['lang'],
        storage: localStorage,
      },
    ],
  },
)
