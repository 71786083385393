import axios from 'axios'
import { useAuth } from '@/auth/useAuth'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.url) {
      return config
    }
    const auth = useAuth()
    const isAdmin = auth.user.roles && auth.user.roles.includes('admin')
    const isAnalyst = auth.user.roles && auth.user.roles.includes('analyst')
    const isSource = auth.user.roles && auth.user.roles.includes('source')

    if (isAdmin) {
      if (
        !(config.url.includes('Category') || config.url.includes('Status') || config.url.includes('Speciality')) &&
        config.url.includes('ExamSource')
      ) {
        const isExamsUrlWithId = /\/Exams\/\d+$/.test(config.url)
        const isExamsSearchUrl = config.url.includes('?')

        if (
          !config.url.includes('Report') &&
          !config.url.includes('Search') &&
          !config.url.includes('Administrator/ExamSource')
        ) {
          if (!isExamsUrlWithId || isExamsSearchUrl) {
            config.url = config.url.replace('ExamSource', 'Administrator')
          }
        }
      }
    } else if (isAnalyst) {
      if (!config.url.includes('Category') && config.url.includes('ExamSource')) {
        config.url = config.url.replace('Analyst/Exams', 'Analyst/Physicians')
      }
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response && (error.response.status === 401 || error.response.status == 500)) {
    //   localStorage.clear()
    //   window.location.href = '/'
    // }
    return Promise.reject(error)
  },
)

export default axiosInstance
