import { RouteLocationRaw, Router } from 'vue-router'
import { RequiredAuthOptions } from './types'
import { useToast } from 'vue-toastification'
import { useAuth } from '@/auth/useAuth'

export function configureNavigationGuards(router: Router, options: RequiredAuthOptions) {
  const auth = useAuth()

  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const toast = useToast()

    const userRole = (auth.user.roles && auth.user.roles[0]) || 'visitor'

    // Rota de login
    if (to.name === options.loginRouteName) {
      if (auth.isAuthenticated) {
        console.log('🔒 Usuário já está autenticado. Redirecionando para rota de redirecionamento de login.')
        return { path: '/' }
      }
      console.log('🚪 Rota de login detectada, permitindo acesso.')
      return true
    }

    // Verifica se o usuário está autenticado para rotas restritas
    if (!to.meta.public) {
      if (!auth.isAuthenticated) {
        console.log('❌ Acesso negado. Usuário não autenticado.')
        toast.info('Área restrita, faça login primeiro.', { timeout: 2000 })
        return { name: options.loginRouteName }
      }

      const roleRouteMap: { [key: string]: string[] } = {
        admin: ['/admin', '/access', '/masks', '/applications', '/users', '/sources', '/files', '/exams/details', '/'],
        source: ['/operational', '/files', '/exams/details', '/search-document', '/'],
        analyst: ['/analyst', '/'],
        physician: ['/'], // Página inicial como padrão
      }

      // Função para verificar se a rota atual está entre as permitidas
      const isRouteAllowed = (route: string, allowedRoutes: string[]): boolean => {
        return allowedRoutes.some((allowedRoute) => route.startsWith(allowedRoute))
      }

      // Verifica se o papel do usuário tem rotas permitidas
      if (!roleRouteMap[userRole]) {
        console.log('⚠️ Acesso negado. Papel do usuário é inválido ou não permitido.')
        toast.error('Você não tem permissão para acessar esta página.', { timeout: 9000 })
        return { path: '/' }
      }

      // Verifica se a rota atual é permitida para o papel do usuário
      const currentRoute = to.path
      if (!isRouteAllowed(currentRoute, roleRouteMap[userRole])) {
        console.log('⚠️ Acesso negado. Rota não permitida para o papel do usuário.')
        toast.error('Você não tem permissão para acessar esta página.', { timeout: 9000 })
        return { path: '/' }
      }

      console.log('✅ Acesso permitido. Usuário com papel:', userRole)
    }

    // Redireciona para a rota raiz baseada no papel do usuário
    if (to.path === '/') {
      if (userRole === 'admin') {
        return { path: '/admin' }
      } else if (userRole === 'source') {
        return { path: '/operational' }
      } else if (userRole === 'analyst') {
        return { path: '/analyst' }
      } else if (userRole === 'physician') {
        if (auth.user.physician?.listExams) {
          return { path: '/radio-report' }
        } else if (auth.user.physician?.onlyReports) {
          return { path: '/exams-report' }
        } else {
          return { path: '/' }
        }
      }
    }

    return true
  })
}
